import Vue from 'vue'
import PouchDB from 'pouchdb';

const config = require('@/config');

const namespaced = true

const state = {
    db: new PouchDB(config.conference),

}

const actions = {
    async isFilled({state}) {
        let res = (await state.db.info()).doc_count > 100;
        return res;
    },

//deleting database
    async destroyDB({state}) {
        let res = (await state.db.destroy())
        return res
    },

    upsertBulk({state}, payload) {
        let docs = payload.docs;
        let opts = payload.opts;
        return new Promise((resolve) => {
            const allDocsOpts = {
                keys: docs.map(doc => doc._id)
            };
            if (!opts.replace) {
                allDocsOpts.include_docs = true;
            }
            return state.db.allDocs(allDocsOpts)
                .then(res => {
                    return docs.map(doc => {
                        const row = res.rows.find(row => row.id === doc._id);
                        if (!row || row.error) {
                            return doc
                        }
                        if (!opts.replace) {
                            return Object.assign({}, row.doc, doc)
                        }
                        return Object.assign({}, doc, {
                            _rev: row.value.rev
                        })
                    })
                })
                .then(docs => {
                    state.db.bulkDocs(docs).then(() => {
                        resolve(true);
                    });
                }).catch((err) => {
                    console.log('upsertbulk', err, docs);
                })
        });
    },

    getItem({state}, payload) {
        let id = payload.id;
        let raw = payload.raw;
        let table = payload.table;
        return new Promise((resolve, reject) => {
            state.db.get(table + '/' + id).then(_item => {
                if (raw) {
                    resolve(_item);
                } else {
                    resolve(_item.data);
                }
            }).catch((err) => {
                reject(err);
            })
        })
    },

    getItems({state}, payload) {
        console.log("sdijuaduzgsagzasdguias9usadhuadiohu")
        let raw = payload.raw;
        let table = payload.table;
        console.log(state.db)
        return new Promise((resolve, reject) => {
            state.db.allDocs({
                include_docs: true,
                startkey: table, endkey: table + '\uffff'
            }).then((result) => {
                if (raw) {
                    resolve(result);
                } else {
                    let list = [];
                    result.rows.map(row => {
                        let data = row.doc.data;
                        if (data && data.deleted_at == null) {
                            list.push(data);
                        }
                    });
                    resolve(list);
                }
            }).catch((error) => {
                reject(error);
            });
        });

    },

    get({dispatch}, id) {
        return dispatch('getItem', id);
    },

    getAll({dispatch}, raw) {
        return dispatch('getItems', raw);
    },

    async upsertItems({dispatch}, data) {
        let items = data.items;
        let table = data.table;
        let list = [];
        items.forEach(item => {
            if (item.deleted_at && item.deleted_at !== 'null') {
                list.push({'_id': table + '/' + item.id, _deleted: true});
            } else {
                list.push({'_id': table + '/' + item.id, 'data': item});
            }
        });
        return await dispatch('upsertBulk', {docs: list, opts: {replace: true}});
    },

    async initDatabaseItems({state}, data) {
        return new Promise((resolve) => {
            console.log('first init')
            let items = data.items;
            let table = data.table;
            let list = [];
            items.forEach(item => {
                if (item.deleted_at && item.deleted_at !== 'null') {
                    list.push({'_id': table + '/' + item.id, _deleted: true});
                } else {
                    list.push({'_id': table + '/' + item.id, 'data': item});
                }
            });

            //console.log('items to save', list)
            return state.db.bulkDocs(list).then((res) => {
              //  console.log('res for ' + table, res)
                resolve(res);
            }).catch(err => {
                console.log(err)
            })
        });
    },


    getMultiple({state}, tables) {
        //console.log(state, tables)
        //console.log("afterstatetables")
        let promises = [];
        return new Promise((resolve) => {
            tables.forEach(table => {
                promises.push(state.db.allDocs({include_docs: true, startkey: table, endkey: table + '\uffff'}));
            });

            Promise.all(promises).then(results => {
                let result = {};
                tables.forEach(table => {
                    let list = [];
                    let res = results.shift();
                    if (res && res.rows)
                        res.rows.map(row => {
                            let data = row.doc.data;
                            if (data && data.deleted_at == null) {
                                list.push(data);
                            }
                        });
                    result[table] = list;
                });
                resolve(result);
            })
        });
    }
    ,
    getAllObject() {
        return new Promise((resolve, reject) => {
            let items = {};
            this.getAll().then(_items => {
                _items.forEach(item => {
                    items[item.id] = item;
                });
                resolve(items)
            }).catch(err => {
                reject(err);
            })
        })

    }
    ,
    createTableForModel() {
        return new Promise(resolve => resolve())
    }

}
const mutations = {
    setDB: (state, data) => {
        Vue.set(state, 'db', data)
    },
}
const getters = {
    getDB: (state) => {
        return state.db;
    },
}

export const database = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
