const namespaced = true

const state = {}

const actions = {
    async getAllAbstracts2({dispatch}) {
        return await dispatch('database/getItems', {table: 'abstracts'}, {root: true})
    },
    async getAbstract({dispatch}, id) {
        let abstract = null
        try {
            let data = await dispatch('database/getMultiple', ['abstracts','presentations', 'presentation_abstract_links'], {root: true});
            abstract = data['abstracts'].find(abs => abs.id == id);
            let preslink = data['presentation_abstract_links'].find(pal => pal.abstract_id == abstract.id);
            if(preslink){
                let presentation = data['presentations'].find(pres => pres.id == preslink.presentation_id);
                abstract.code = presentation.code;
            }
        } catch (err) {
            console.log('no abstract')
        }
        return abstract;
    },
    async getAllAbstracts({dispatch}) {
        let data = await dispatch('database/getMultiple', ['abstracts','presentations', 'presentation_abstract_links', 'person_roles', 'persons', 'session_presentation_links', 'sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links'], {root: true});
        let abstracts = [];
        for(let i = 0; i < data['abstracts'].length;i++){
            let abstract = data['abstracts'][i];

            let preslink = data['presentation_abstract_links'].find(pal => pal.abstract_id == abstract.id);
            if(preslink){
                let presentation = data['presentations'].find(pres => pres.id == preslink.presentation_id);
                abstract.code = parseInt(presentation.code);
            }
            if(abstract.code){
                abstracts.push(abstract)
            }

        }
        return abstracts;
    },
    async getSessionForAbstract({dispatch}, id) {
        let presentation = {};
        let data = await dispatch('database/getMultiple', ['presentations', 'presentation_abstract_links', 'person_roles', 'persons', 'session_presentation_links', 'sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links'], {root: true});
        let preslink = data['presentation_abstract_links'].find(pal => pal.abstract_id == id);
        if (preslink) {
            presentation = data['presentations'].find(pres => pres.id == preslink.presentation_id);
        }

        let session = data['sessions'].find(sess => sess.id == presentation.session_id)
        console.log('ABSsessions', session)
        let sess_pres_links = data['session_presentation_links'].filter(sesspreslink => sesspreslink.session_id == presentation.session_id);
        sess_pres_links.forEach(sess_pres_link => {
            let session = data['sessions'].find(sess => sess.id == sess_pres_link.session_id);
            let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id);
            sesstype_links.forEach(typelink => {

                let type = data['sessiontypes'].filter(type => type.id == typelink.sessiontype_id)[0];
                session.session_type_text = type.name;
                session.session_type_order = type.order;
                session.is_scientific = type.scientific;
            })
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            session.location = location;
            session.location_order = location ? location.order : 0;

            session.chair = []
            session.speakers = [];
            let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
            sess_pres_links.forEach(sess_pres_link => {
                let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                sessionroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.chair.push(person);
                        }
                    }
                })
                presroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.speakers.push(person);
                        }
                    }
                })
            })
        })
        return [session];
    },
}
const mutations = {}
const getters = {}

export const abstracts = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
